@import 'breakpoints.scss';

.container {
  position: relative;
  z-index: 1;
  background: #ffffff;
  color: #77757b;

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: -150px;
    width: 100%;
    min-width: 100vw;
    height: 150px;
    background: url('../theme/assets/CloudTileTop.svg');
    background-repeat: repeat-x;
    background-position: left calc(50% + 130px) top;
    pointer-events: none; // don't block clicks

    @include to(3) {
      top: -90px;
      background-size: 800px;
      background-position-x: calc(50% + 45px);
    }
  }
}

.title {
  display: flex;
  margin: 35px auto;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;

  &::before,
  &::after {
    --size: 1px;

    content: '';
    flex: 1 1 0;
    background-image: linear-gradient(
      var(--direction, -90deg),
      transparent 10px,
      #9f9f9f 10px,
      transparent 60%
    );
    background-repeat: no-repeat;
    background-position-y: center;
    background-size: 100% var(--size, 1px);
  }

  &::after {
    --direction: 90deg;
  }
}

.logos {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @include to(1) {
    overflow: hidden;
  }

  > * {
    @include from(2) {
      height: 52px;
      margin: 0 20px 30px;
    }
  }

  &.marquee {
    flex-wrap: nowrap;
    justify-content: normal;
  }

  img {
    // make white logos grey. we might want to use the
    // black logos and reduce the opacity instead. just
    // trying to get the structure sketched out.
    filter: invert(0.3);

    @include to(1) {
      height: 23px;
      margin: 0 15px;
    }
  }
}
