@import 'utils.scss';
@import 'breakpoints.scss';

.skyPrimaryButton.skyPrimaryButton {
  width: auto;
  padding: 16px 29px;
}

.skySecondaryButton.skySecondaryButton {
  --border-size: 1px;

  width: auto;
  padding: calc(16px - var(--border-size)) 24px;
  border: var(--border-size) solid var(--color-purple-400);
  background-color: transparent;
  color: var(--color-purple-400);
}

.ctasContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.stickyCtas {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  background: #ffffff;
  box-shadow: 0 6px 30px rgb(149 152 189 / 80%);

  .skyPrimaryButton,
  .skySecondaryButton {
    margin-block: 0;
  }

  // FIXME: don't hardcode this to 3
  :nth-child(1n + 3) {
    display: none;
  }

  &:not(.stickyCtaIsUnmounting) {
    animation: sticky-ctas-in 0.25s ease-out backwards;
  }

  &.stickyCtaIsUnmounting {
    animation: sticky-ctas-out 0.25s ease-out forwards;
  }
}

@keyframes sticky-ctas-out {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(
      calc(100% + 20px)
    ); // +20px to ensure the shadow doesn't pop in/out
  }
}

@keyframes sticky-ctas-in {
  0% {
    transform: translateY(100%);
  }

  100% {
    transform: translateY(0);
  }
}
