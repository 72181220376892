@import 'breakpoints.scss';

.extendedPrompt {
  @include to(1) {
    display: none;
  }
}

.loginLink {
  @include to(1) {
    display: block;
    width: 30px;
    height: 30px;
    overflow: hidden;
    background: url('../theme/assets/LoginIcon.svg');
    background-size: 30px;
    color: transparent !important; // "!important" just to suppress hover color change
  }
}
