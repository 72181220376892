@import 'breakpoints.scss';

.animatedHeaderTextWrapper {
  display: inline-flex;
  position: relative;
  min-width: 500px;
  height: 75px;
  margin-bottom: -15px;
  perspective: 300px;
  animation:
    fade-in 0.5s forwards,
    perspective infinite 1s; /* safari hack */

  opacity: 1;

  @include to(1) {
    min-width: 330px;
    height: 53px;
  }

  &.animatedHeaderPhrase {
    top: 0;
    left: 0;
    white-space: nowrap;
  }
}

.animatedHeaderPhrase {
  position: absolute;
  transform-origin: 50% 100%;
}

.hidden {
  visibility: hidden;
}

.visible {
  visibility: visible;
}

.rotateIn {
  transform: translate3d(0, 0, 0) rotate3d(1, 0, 0, 0deg);
  animation: rotate-in 0.75s;
  opacity: 1;
}

.rotateOut {
  transform: translate3d(0, 0, 0) rotate3d(1, 0, 0, 180deg);
  animation: rotate-out 0.75s;
  opacity: 0;
}

// #region animations

@keyframes perspective {
  0% {
    perspective: 299px;
  }

  100% {
    perspective: 300px;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  95% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes rotate-in {
  0% {
    transform: translate3d(0, 0, 0) rotate3d(1, 0, 0, 180deg);
    opacity: 0;
  }

  35% {
    transform: translate3d(0, 0, 0) rotate3d(1, 0, 0, 120deg);
    opacity: 0;
  }

  60% {
    opacity: 0;
  }

  100% {
    transform: translate3d(0, 0, 0) rotate3d(1, 0, 0, 360deg);
    opacity: 1;
  }
}

@keyframes rotate-out {
  0% {
    transform: translate3d(0, 0, 0) rotate3d(1, 0, 0, 0deg);
    opacity: 1;
  }

  35% {
    transform: translate3d(0, 0, 0) rotate3d(1, 0, 0, -40deg);
    opacity: 1;
  }

  60% {
    opacity: 0;
  }

  100% {
    transform: translate3d(0, 0, 0) rotate3d(1, 0, 0, 180deg);
    opacity: 0;
  }
}

// #endregion
