@import 'utils.scss';
@import 'breakpoints.scss';

.container {
  color: var(--color-text-primary);
  gap: 1rem;
}

.bgMyPlan {
  background-color: #b0bcf3;
  background-image: url('../theme/assets/cloud_small.svg'),
    url('../theme/assets/cloud_med.svg'),
    radial-gradient(
      74.49% 74.49% at 50% 100%,
      #ffffff 0%,
      rgb(255 255 255 / 0%) 100%
    );
  background-repeat: no-repeat;
  background-position:
    left -26px top 165px,
    right -58px top 317px,
    bottom center;

  @include to(1) {
    background-position:
      left -86px top 80px,
      right -113px top 490px,
      bottom center;
  }
}

.content {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 1024px;
  margin: 0 auto;

  @include to(2) {
    flex-direction: column;
    align-items: center;
    max-width: 65ch;
    margin: 100px auto -215px;
    padding-inline: 2rem;
  }
}

.content > * {
  flex: 1 1 50%;
}

header > h5 {
  margin-top: 15px;
  margin-bottom: 42px;

  @include to(2) {
    margin-bottom: 30px;
  }
}

.imageContainer {
  position: relative;
  top: 110px;
  text-align: center;

  @include from(3) {
    padding-left: 70px;
  }

  @include to(2) {
    top: 55px;
  }
}

.image {
  width: 362px;
  height: 100%;
  object-fit: cover;

  @include to(2) {
    width: 315px;
  }
}
