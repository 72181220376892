@use '../theme/mixins.scss';

.headerText {
  max-width: 21ch;
  margin-top: 75px;
  text-align: center;
}

.variantGradient {
  @include mixins.gradientText;
}
