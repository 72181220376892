@import 'colors.scss';

.specificityWrapper {
  .button {
    width: 100%;
    height: 50px;
    padding: 0;
    border-radius: 10px;
    background-color: $white;

    &:hover {
      background-color: $grey200;
    }
  }

  .button.button {
    padding: 0 16px;
  }

  .buttonContent {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    height: 100%;
  }

  .buttonText {
    margin-left: 0.4em;
    color: $grey700;
    font-size: 16px;
    font-weight: 400;
    line-height: 1em;
  }
}
