@import 'breakpoints.scss';

.cardCtaLink {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  gap: 1rem;
  color: var(--color-cool-purple-700);

  @include to(1) {
    grid-template-columns: 1fr;

    &:first-child {
      width: 171px;
      height: 34px;
    }
  }
}
