@import 'utils.scss';
@import 'breakpoints.scss';

.container.container {
  background: inherit;
  color: var(--color-cool-purple-600);
  font-size: 18px;

  @include from(2) {
    max-width: 880px;
    margin: 0 auto;
  }

  a {
    line-height: 1.5em;

    @include to(1) {
      line-height: 2em;
    }
  }
}

.container.container *,
.container.container a:is(:visited, :link, :hover, :active) {
  color: var(--color-cool-purple-600);
}

.category.category {
  @include to(1) {
    margin-bottom: 40px;
  }

  &:first-child {
    .categoryName {
      display: none;
    }

    .items {
      margin-top: 4px;
    }
  }
}

.categoryName.categoryName {
  font-weight: 700;

  @include to(1) {
    margin-bottom: 20px;
  }
}

.text.text {
  color: var(--color-cool-purple-600);
  font-size: 18px;

  @include from(2) {
    margin-left: 25%;
  }
}

.items > li {
  margin-top: 0;
}

.items img {
  width: 120px;
}
