@import 'utils.scss';
@import 'breakpoints.scss';

.h1,
.h2,
.h3 {
  font-weight: var(--font-weight-heading-primary, 800);
}

.h1 {
  font-size: var(--font-size-heading-primary, 62px);

  @include to(1) {
    font-size: var(--font-size-heading-primary-mobile, 44px);
  }
}

.h2 {
  font-size: var(--font-size-heading-primary, 62px);

  @include to(1) {
    font-size: 32px;
  }
}

.h3 {
  font-size: var(--font-size-heading-secondary, 42px);

  @include to(1) {
    font-size: 28px;
  }
}

.h5 {
  font-size: 24px;
  font-weight: 400;
}
