@import 'sizes.scss';

.container {
  display: flex;
  position: absolute; // just to allow content to run under it
  z-index: 999;
  width: 100%;
  max-width: calc(100vw - 4rem);
  min-height: 70px;
  margin: 0 auto;
  padding-top: 40px;
  inset: 0 0 auto;
}

.container > * {
  flex: 1 1 100%;
}

.nav {
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: space-between;
  max-width: $maxWidth;
  margin: 0 auto;
  font-size: 18px;
}

.logo {
  width: 170px;
  height: 30px;
  object-fit: contain;
}
